import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useTourStore = defineStore('tour', {
  state: () => ({
		filters: { "search": "" }
	}),

  getters: {
		tableFilters: state => state.filters,
	},

  actions: {
    index: (filters = {}) => {
      const page = filters.page ? filters.page + 1 : 1

      return new Promise((resolve) => {
				axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/tours`, {
					params: { ...filters, page }
				}).then(({data}) => {
					resolve(data)
				}).catch(() => resolve(null))
			})
    },
    show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}`),
		store: (tour) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/tours`, tour),
		update: (id, tour) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}`, tour),
		updateCategories: (id, categories) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/categories`, categories),
		updateCollections: (id, collections) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/collections`, collections),
		updateDetails: (id, details) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/details`, details),
		updateGallery: (id, formData, headers) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/gallery`, formData, { headers } ),
		updateGalleryRemove: (id, url) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/galleryRemove`, url),
		updateHotels: (id, hotels) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/hotels`, hotels),
		updatePDF: (id, formData, headers) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/pdf`, formData, { headers } ),
		updatePeriods: (id, periods) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/periods`, periods),
		updatePictureSocialNetwork: (id, formData, headers) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/tours/${id}/picture-social`, formData, { headers } ),
  }
})
